import React from 'react';
import { Routes, Route } from 'react-router-dom'
import './App.css';
import HomePage from './pages/Home';
import Certificate from './components/Certificate'

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="certificate" element={<Certificate />} />
      </Routes>
    </div>
  );
}
export default App;
