import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col, Avatar, notification, Card } from "antd";
import {
    TwitterOutlined as TwitterIcon, GithubOutlined as GithubIcon,
    LinkedinOutlined as LinkedInIcon,
    MailOutlined as MailIcon, FileSearchOutlined as FileIcon, MediumCircleFilled as MediumIcon
  } from '@ant-design/icons';
import {motion, useAnimation, useMotionValue, useMotionValueEvent} from 'framer-motion'
import { getTopHighScores } from "../utils/backendService";
import HighScoreModal from "./HighScoreModal";
import profile from "./7703723.jpg";
import HighScoreList from "./HighScoreList";
import resume from "../Zawadzki-Resume.pdf";


const IconWrapper = ({ children }) => {
    return (
        <motion.div
            whileHover={{scale: 1.3}}
            onHoverStart={e => {}}
            onHoverEnd={e => {}}
        >
            {children}
        </motion.div>
    )
}


const UserImageNew = () => {
    const [counter, setCounter] = useState(0)
    const [gameStarted, setGameStarted] = useState(false)
    const [highScores, setHighScores] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [latestHighScore, setLatestHighScore] = useState(0)
    const controls = useAnimation();
    const yPosition = useMotionValue(0);


    useEffect(() => {
        setTimeout(() => {
            notification.info({
              message: "Thanks For Swinging By",
              description: "Try clicking on my image for a quick game."
            });
          }, 3000);

          if (highScores === null) {
            getTopHighScores().then((result) => {
                setHighScores(result?.data?.highScores || [])
              }).catch((ex) => console.error(ex))
          }
    }, []) // eslint-disable-line

    useMotionValueEvent(yPosition, "change", (latest) => {
        if (latest === 0) {
          let isNewHighScore = false 
          if (highScores && highScores.length > 5) {
            highScores.forEach((score) => {
              if (score.high_score < counter) {
                isNewHighScore = true
              }
            })
          } else if (highScores?.length < 5) {
            isNewHighScore = true
          }
          if (isNewHighScore) {
            setIsModalOpen(true)
            setLatestHighScore(counter)
          }
            setCounter(0)

            controls.start({
                scaleX: [1.45, 1, 1.3, 1, 1.25, 1], 
                scaleY: [0.925, 0.95, 0.975, 0.995, 1],
                transition: {
                    yoyo: Infinity,
                    ease: 'easeInOut',
                    duration: 1.5
                }
                
            })
        }
    })

    const createScoreCard = () => {
        return (
          <Card className="card">
            <p style={{ margin: 0 }}>Score: {counter}</p>
            <HighScoreList highScores={highScores} />
          </Card>
        );
      }
    const bounceAnimation = useMemo(() => {
        let lowerMultiplerValue = 3
        let upperMultiplerValue = 5
        if (counter > 30) {
          lowerMultiplerValue = 4
        } else if (counter > 60) {
          lowerMultiplerValue = 5
          upperMultiplerValue = 7
        } else if (counter > 100) {
          lowerMultiplerValue = 5
          upperMultiplerValue = 8
        } else if (counter > 160) {
          lowerMultiplerValue = 6
          upperMultiplerValue = 9
        }
        const multiplier = Math.floor(Math.random() * (upperMultiplerValue - lowerMultiplerValue) + lowerMultiplerValue);
        const bouncePosition = yPosition.get() < 0 ? Math.ceil((yPosition.get() * multiplier)) : -75
       return {
        y: [yPosition.get(), bouncePosition, 0],
            transition: {
                yoyo: Infinity,  // Keeps the item bouncing indefinitely
                ease: "easeOut",
                duration: 0.75,
            }
       }
    }, [counter, yPosition])


    const handleOnClick = () => {
        if (!gameStarted) setGameStarted(true)
        controls.start(bounceAnimation);
        setCounter(counter + 1)
      };

    const avatarSize = window.screen.height >= 646 ? 300 : 200
    return (
      <>
        <HighScoreModal
          isOpen={isModalOpen}
          highScore={latestHighScore}
          onModalOk={(highScores) => {
            setHighScores(highScores)
            setIsModalOpen(false)
            setCounter(0)            
          }}
          onModalCancel={() => {
            setIsModalOpen(false)
            setCounter(0)
          }}
        />
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Row type="flex" justify="center" align="middle">
              <Col className="avatar" id="avatarCol">
                <motion.div
                    initial={{y:0}}
                    whileTap={{scale: 1.4}}
                    animate={controls}
                    onClick={handleOnClick}
                    style={{y: yPosition}}
                    whileHover={{scale: 1.1}}
                >
                    <Avatar
                    src={profile}
                    size={avatarSize}
                    shape="circle"
                    key={1}
                    className="avatarBall"
                    />
                </motion.div>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col>
                <h1 className="dShadow" style={{ margin: 0 }}>
                  Patrick M. Zawadzki
                </h1>
              </Col>
            </Row>
            <Row type="flex" justify="center" align="middle">
              <Col>
                <h2 style={{ marginBottom: 10, marginTop:10}}>
                  Senior Software Engineer @ <a target='_blank' rel="noopener noreferrer" href="https://www.netflix.com/">Netflix</a>
                </h2>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="center"
              align="middle"
              gutter={4}
              className="iconRow"
            >
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.twitter.com/patzawa"
                >
                    <IconWrapper>
                        <TwitterIcon />
                    </IconWrapper>
                </a>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.github.com/zawadzkip"
                >
                    <IconWrapper>
                      <GithubIcon />
                    </IconWrapper>
                </a>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://patzawad.medium.com/"
                >
                <IconWrapper>
                  <MediumIcon />
                  </IconWrapper>
                </a>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.linkedin.com/in/patrickzawadzki"
                >
                    <IconWrapper>
                    <LinkedInIcon />
                    </IconWrapper>
                </a>
              </Col>
              <Col>
                <a target="_blank" rel="noopener noreferrer" href={resume}>
                <IconWrapper>
                    <FileIcon />
                  </IconWrapper>
                </a>
              </Col>
              <Col>
                <a href="mailto:patrickmzawadzki@gmail.com">
                    <IconWrapper>

                  <MailIcon />
                  </IconWrapper>
                </a>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ marginTop: 10 }}
              className="cardRow"
            >
              <Col>{gameStarted && createScoreCard()}</Col>
            </Row>
          </Col>
        </Row>
      </>
    );

}

export default UserImageNew 