import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { postHighScore, getTopHighScores, getUserNameExists } from '../utils/backendService';
import { Modal, Input, notification } from 'antd'
import { isValidHighScoreUser } from '../utils';

const CongratsEmoji = () => <span role="img" aria-label="Congratulations Emoji">🎉</span>

export default function HighScoreModal(props) {
    const {
        isOpen,
        highScore,
        onModalOk = () => { },
        onModalCancel = () => { }
    } = props;
    const [userName, setUserName] = useState("")
    const [isModalLoading, setModalLoading] = useState(false)
    const handleModalOk = async () => {
        if (!isValidHighScoreUser(userName)) {
            notification.error({
                message: "Error",
                description: "Your user name must be three characters long, only using A-Z"
            })
            return;
        }
        try {
            setModalLoading(true)
            const userNameResult = await getUserNameExists(userName)
            if (userNameResult?.data?.userExists) {
                notification.error({
                    message: "Error",
                    description: `"${userName}" already exists, please choose a different user name.`
                })
                setModalLoading(false)
                return;
            }
            // TODO update to check if the username exists before posting a high score
            const result = await postHighScore(highScore, userName)
            if (result?.data?.success) {
                const highScoreResult = await getTopHighScores()
                onModalOk(highScoreResult?.data?.highScores)
                setUserName("")

            }
        } catch (ex) {
            console.error(ex)
            notification.error({
                message: "Error",
                description: "An error occurred saving your user name, please try again."
            })
        }

        setModalLoading(false)
    }
    const handleModalCancel = () => {
        setUserName("")
        onModalCancel()
    }
    console.log(isOpen)
    return (
        <Modal
            title="New High Score!"
            open={isOpen}
            confirmLoading={isModalLoading}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            maskClosable={false}>
            <p><strong><CongratsEmoji /> Congratulations! <CongratsEmoji /></strong></p>
            <p>You've achieved a new high score of <strong>{highScore}</strong> clicks!</p>
            <p>I'm not quite sure why you've decided to keep clicking, but now you have bragging rights.</p>
            <p>Enter a 3 character name <em>in all caps</em> for yourself, only letters.</p>
            <p>You can <strong>only use this name once</strong> so be sure you've tried your <a className="modal" href="https://youtu.be/rg6CiPI6h2g?t=6" target="_blank" rel="noopener noreferrer">hardest!</a></p>
            <p>For bonus bragging rights, <Link className="modal" to={`/certificate?username=${userName}&score=${highScore}`} target="_blank" rel="noopener noreferrer"> create your certificate of achievement.</Link></p>
            <Input placeholder="AAA" maxLength={3} value={userName} onChange={e => { setUserName(e.target.value) }} />
        </Modal>
    )
}