import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Input, Divider, Card } from 'antd';
import moment from 'moment'
import awardImage from './award.jpg'

const FONT_SIZE = {
    NORMAL: 'NORMAL',
    SUB: 'SUB',
    SMALLEST: 'SMALL'
}

const useQuery = () => {
    const { search } = useLocation()
    return Object.fromEntries(new URLSearchParams(search))
}


const getFont = (fontMultiplier = FONT_SIZE.NORMAL, canvas) => {
    var fontBase = 1000, // selected default width for canvas
        fontSize = 40; // default size for font
    var ratio = fontSize / fontBase; // calc ratio
    var size = canvas.width * ratio; // get font size based on current width
    switch (fontMultiplier) {
        case FONT_SIZE.SUB:
            size = size * 0.75;
            break;
        case FONT_SIZE.SMALLEST:
            size = size * 0.6
            break;
        default:
            break;
    }
    return `${size | 0}px handwriting, sans-serif`;
}

const NoPrintDiv = ({ children }) => {
    const style = {
        '@media print': {
            display: 'none'
        }
    }
    return (
        <div style={style}>
            {children}
        </div>
    )
}

export default function Certificate() {
    const { username, score } = useQuery()
    const canvasRef = useRef(null)
    const [context, setContext] = useState(null)
    const [name, setName] = useState(username || "")
    useEffect(() => {
        if (canvasRef.current) {
            const renderContext = canvasRef.current.getContext('2d')
            if (renderContext) {
                const img = new Image();
                img.src = awardImage
                img.onload = () => {
                    canvasRef.current.width = img.width;
                    canvasRef.current.height = img.height;
                    renderContext.drawImage(img, 0, 0);
                    renderContext.font = getFont(FONT_SIZE.NORMAL, canvasRef.current);
                    renderContext.fillText(name, 290, 550)
                    renderContext.font = getFont(FONT_SIZE.SUB, canvasRef.current)
                    renderContext.fillText(`A "Flappy Pat" High Score of ${score}`, 290, 800)
                    renderContext.font = getFont(FONT_SIZE.SMALLEST, canvasRef.current)
                    renderContext.fillText("Patrick Zawadzki", 450, 1010)
                    renderContext.fillText(moment().format("MMM DD, YYYY"), 1135, 1010)
                }
                setContext(renderContext)
            }
        }

    }, [context, name, score])
    return (
        <main>
            <NoPrintDiv>
                <Card title="Customize your Certificate">
                    <p>By default, your 3 Character Username from the other page goes here, but you can also enter your own name for that extra special feeling of accomplishment.</p>
                    <p>Once you're satisfied with your certificate, <strong>Right click on the certificate and save it</strong> maybe print it out and put it on your fridge.</p>
                    <Input placeholder="Your Name Goes here" allowClear value={name} onChange={e => setName(e.target.value)} />
                </Card>
                <Divider />
            </NoPrintDiv>

            {
                // eslint-disable no-undef
            }
            <canvas id="cert-canvas" ref={canvasRef} />
            {
                // eslint-enable no-undef
            }
        </main>
    )
}