import axios from 'axios'
const baseAxios = axios.create({
    baseURL: false ? 'http://localhost:3001/.netlify/functions' : '/.netlify/functions'
})

export async function getTopHighScores(queryAmount = 5) {
    return baseAxios.get(`/getTopHighscores`, {
        params: {
            total: queryAmount
        }
    })
}

export async function postHighScore(highScore, userId) {
    return baseAxios.post(`/postHighScore`, {
        userId,
        highScore
    })
}

export async function getUserNameExists(username) {
    return baseAxios.get('/getUserIdExists', {
        params: {
            user: username
        }
    })
}

