import React, { useEffect, useState, useRef } from 'react';
import WAVES from 'vanta/dist/vanta.waves.min'


export default function WaveBackground({ children }) {
    const [vantaEffect, setVantaEffect] = useState(0);
    const myRef = useRef(null);
    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(WAVES({
                el: myRef.current,
                color: 0xB00710,
                waveHeight: 30,
                waveSpeed: 1.25,
                zoom: 0.8,
                scale: 1.0,
                scaleMobile: 1.00,
                shininess: 85,
            }));
        }
        return () => {
            if (vantaEffect) {
                vantaEffect.destroy();
            }

        };
    }, [vantaEffect])
    return (
        <div className='fullscreen-bg' ref={myRef}>
            {
                children
            }
        </div>
    )
}
