import React from 'react'

const NoMarginP = ({ children }) => <p style={{ margin: 0 }}>{children}</p>

export default function HighScoreList({ highScores }) {
    const highScoresToMap = highScores || []
    return (<div>
        <NoMarginP>
            <strong>Leaderboard</strong>
            &nbsp;
            {highScoresToMap.map((score) => {
                return (
                    `${score.high_score} (${score.user_id})`
                )
            }).join(', ')}
        </NoMarginP>

    </div>)
}