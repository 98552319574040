import React from 'react'
import UserImage from '../components/UserImageNew'
import WaveBackground from '../components/WaveBackground'

export default function HomePage() {
    return (
        <main>
            <WaveBackground>
                <UserImage />
            </WaveBackground>
        </main>
    )
}